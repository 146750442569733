const FormRulesMixin = {
  data: function () {
    return {
      emailRules: [
        (v) => !!v || "The email is required",
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*/.test(v) ||
          "The entered email is invalid",
      ],
      nameRules: [
        (v) => !!v || "The username is required",
        (v) =>
          (v && v.length <= 10) ||
          "The username must contain less than 10 characters",
      ],
      siteNameRules: [
        (v) => !!v || "The website name is required",
        (v) =>
          (v && v.length <= 25) ||
          "The website name must contain less than 25 characters",
      ],
      domainRules: [
        (v) => !!v || "The domain is required",
        (v) =>
          (v && v.length <= 63) ||
          "The domain must contain less than 64 characters",
        (v) =>
          (v && v.length >= 4) ||
          "The domain must contain at least 4 characters",
        // domain can contain letters, numbers dots hyphen
        (v) => /^[a-zA-Z0-9.-]*$/.test(v) || "Entered domain is not valid",
      ],
      checkboxRules: [(v) => !!v || "Item is required"],
      adminPasswordRules: [
        (v) => !!v || "The password is required",
        (v) =>
          (v && v.length <= 25) ||
          "The password must contain less than 25 characters",
        (v) =>
          (v && v.length >= 6) ||
          "The password must contain at least 6 characters",
      ],
    };
  },
};

export default FormRulesMixin;
