<template>
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.09 14.3263C12.7691 15.3849 11.1607 15.9715 9.5 16C4.09091 16 1 9.50001 1 9.50001C1.96119 7.61655 3.38433 6.29724 5 5M7.87727 3.19502C8.40917 3.06411 8.95373 2.99867 9.5 3.00002C14.9091 3.00002 18 9.50001 18 9.50001C17.5309 10.4227 16.9715 11.2914 16.3309 12.0919M11.1382 11.2225C10.926 11.462 10.67 11.6541 10.3857 11.7873C10.1013 11.9205 9.79433 11.9922 9.48307 11.9979C9.17181 12.0037 8.86263 11.9435 8.57397 11.8209C8.28532 11.6983 8.0231 11.5158 7.80297 11.2844C7.58284 11.0529 7.4093 10.7772 7.29271 10.4737C7.17612 10.1702 7.11886 9.8451 7.12435 9.51781C7.12984 9.19053 7.19797 8.86776 7.32468 8.56876C7.45138 8.26977 7.57521 8.22315 7.80297 8" stroke="#07C07E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M1 1L18 18" stroke="#07C07E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {};
</script>

<style>
</style>