<template>
  <div class="d-flex flex-column justify-center">
    <style-manager-editor
      v-for="(item, index) in selectedColors"
      :key="'color-' + index"
      @update:currentColor="applyChanges"
      :currentColor="item"
      :colorKey="index"
      :colorName="colorNames[index]"
    />
  </div>
</template>

<script>
import StyleManagerEditor from "./StyleManagerEditor.vue";

export default {
  components: {
    StyleManagerEditor,
  },
  data: function () {
    return {
      colorNames: {
        base: "Base Color",
        darken1: "Darken Color 1",
        darken2: "Darken Color 2",
        darken3: "Darken Color 3",
        darken4: "Darken Color 4",
        darken5: "Darken Color 5",
        lighten1: "Lighten Color 1",
        lighten2: "Lighten Color 2",
        lighten3: "Lighten Color 3",
        lighten4: "Faded Color",
        lighten5: "Faded Color 2",
      },
    };
  },
  props: {
    selectedColors: {
      type: Object,
    },
  },
  methods: {
    applyChanges: function (data) {
      let newTheme = { ...this.selectedColors, [data.index]: data.item };

      this.$emit("update:selectedColors", newTheme);
    },
  },
};
</script>

<style>
</style>