var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"hide-overlay":"","overlay-opacity":0,"transition":"fade-transition","fullscreen":"","no-click-animation":"","refs":"dialog"},model:{value:(_vm.dialogOpen),callback:function ($$v) {_vm.dialogOpen=$$v},expression:"dialogOpen"}},[_c('v-card',[_c('div',{class:_vm.$vuetify.breakpoint.mobile ? 'px-6' : 'px-16'},[_c('v-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"pt-8 d-flex justify-center flex-column align-center top-info"},[_c('v-img',{attrs:{"width":_vm.$vuetify.breakpoint.mobile ? 171 : 200,"height":_vm.$vuetify.breakpoint.mobile ? 36 : 44,"src":_vm.customLogo ? _vm.customLogo : require('@/assets/logo.svg'),"contain":"","position":"center center"}}),_c('h1',{staticClass:"pt-8"},[_vm._v("Choose Theme")]),_vm._v(" No sweat, you can always change it later. ")],1),_c('v-row',{attrs:{"no-gutters":""}},[(!_vm.loadingThemeCategories)?_c('tags-sidebar',{attrs:{"groups":_vm.themeCategories,"group":_vm.selectedThemeCategory,"allThemesCount":_vm.allThemesCount,"default-theme-categories":_vm.defaultThemeCategories,"recommendedThemes":_vm.recommendedThemes},on:{"goBack":function($event){return _vm.$emit('goBack')},"update:group":function($event){_vm.selectedThemeCategory=$event}}}):_c('tags-sidebar-skeleton'),_c('v-col',{class:[
              _vm.$vuetify.breakpoint.lgAndDown ? '' : 'pl-12',

              _vm.$vuetify.breakpoint.mobile ? 'pt-0' : 'pl-6' ],staticStyle:{"position":"relative"},attrs:{"lg":10,"md":9,"sm":12}},[_c('v-row',{staticClass:"d-flex align-center justify-space-between mb-4 sticky-filters",class:_vm.$vuetify.breakpoint.mobile ? 'mt-0 mx-4' : 'mt-8 mx-4'},[_c('div',{staticClass:"d-flex w-100",class:_vm.$vuetify.breakpoint.smAndDown ? 'flex-column' : ''},[(_vm.$vuetify.breakpoint.mobile)?_c('v-btn',{ref:"switch",staticClass:"mt-0 pt-0",class:_vm.$vuetify.breakpoint.smAndDown ? 'mb-5' : '',staticStyle:{"max-width":"300px !important"},attrs:{"block":_vm.$vuetify.breakpoint.smAndDown ? true : false,"elevation":"0","id":"#switch","outlined":"","small":"","color":"gray"},on:{"click":function($event){return _vm.$emit('goBack')}}},[_c('v-icon',{attrs:{"size":"16px","color":"gray--darken-1"}},[_vm._v(" $arrowleft ")]),_vm._v(" Back to Dashboard ")],1):_vm._e(),_c('div',{staticClass:"d-flex ml-auto align-center"},[_c('data-iterator-filter-controls',{attrs:{"sortBy":_vm.sortBy,"sortDesc":_vm.sortDesc,"keys":_vm.keys,"showSearchTerm":true,"searchTerm":_vm.searchTerm,"fullWidthSearch":false},on:{"update:sortBy":_vm.changeSortBy,"update:sortDesc":_vm.handleSortDescChange,"update:searchTerm":_vm.handleSearchTermChange}})],1)],1)]),_c('v-data-iterator',{staticClass:"d-flex mx-4 flex-wrap flex-row",attrs:{"disable-pagination":"","items":_vm.themes,"hide-default-footer":"","sort-by":_vm.sortBy.value,"sort-desc":_vm.sortDesc,"search":_vm.searchTerm},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('div',{staticClass:"d-flex flex-wrap w-100"},_vm._l((8),function(item){return _c('v-col',{key:item,staticClass:"px-4 py-4",attrs:{"xl":3,"lg":4,"md":6,"sm":6,"xs":12}},[_c('TemplateCardSkeleton')],1)}),1)]},proxy:true},{key:"default",fn:function(ref){
            var items = ref.items;
return [(_vm.loadingThemes)?_c('v-row',{staticClass:"d-flex w-100 flex-wrap"},_vm._l((8),function(item){return _c('v-col',{key:item,staticClass:"px-4 py-4",attrs:{"xl":3,"lg":4,"md":6,"sm":6,"xs":12}},[_c('TemplateCardSkeleton')],1)}),1):_c('v-row',{staticClass:"d-flex flex-wrap"},[_vm._l((items),function(item,index){return _c('v-col',{key:((item.slug) + "-" + index),staticClass:"py-4 px-4",attrs:{"xl":3,"lg":4,"md":6,"sm":6,"xs":12}},[_c('TemplateCard',{attrs:{"item":item},on:{"selectTheme":_vm.selectTheme}})],1)}),(
                      !_vm.loadingThemes && !_vm.allThemesLoaded && _vm.themes.length > 0
                    )?_c('div',{staticClass:"d-flex align-center justify-center flex-column",staticStyle:{"height":"92px","width":"100%"}},[_c('v-progress-circular',{attrs:{"color":"primary","size":"24","indeterminate":"","width":"4"}}),_c('span',{staticClass:"primary--text p-4 mt-3"},[_vm._v("Loading...")])],1):_vm._e()],2)]}},{key:"no-data",fn:function(){return [(_vm.searchTerm)?[_c('data-iterator-no-results-container',{attrs:{"searchTerm":_vm.searchTerm},on:{"clearAllFilters":_vm.clearFilters}})]:[_c('div',{staticClass:"no-data-container d-flex flex-column align-center justify-center mt-10 mr-20"},[_c('theme-no-data-img'),_c('h4',{staticClass:"mb-4"},[_vm._v("No templates found")]),_c('p',{staticClass:"p-1"},[_vm._v(" We've found no templates. Please check back in a moment ")])],1)]]},proxy:true}])})],1)],1)],1)],1)]),(!_vm.loadingThemes && !_vm.allThemesLoaded && _vm.themes.length > 0)?[_c('div',{directives:[{name:"intersect",rawName:"v-intersect.quiet",value:(_vm.infiniteScrolling),expression:"infiniteScrolling",modifiers:{"quiet":true}}]})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }