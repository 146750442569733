<template>
  <v-card
    elevation="4"
    class="alert alert--success"
    @mouseover="resetTimeout"
    @mouseleave="alertTimeout"
  >
    <div class="alert__icon-box">
      <v-icon color="success" size="20">$checkcircle</v-icon>
    </div>
    <div class="alert__text-box">
      <p class="p-2 font-weight-bold mb-3">Success</p>

      <p class="mb-0 p-2">
        <template v-if="item.successMessage">
          {{ item.successMessage }}
        </template>
        <template v-else-if="item.html_text">
          <div v-html="item.html_text" />
        </template>
        <template v-else> error </template>
      </p>
    </div>
    <div class="alert__close-box">
      <v-btn icon small @click="removeAlert">
        <v-icon size="16">$close</v-icon>
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import AlertMixin from "../../mixins/AlertMixin";

export default {
  mixins: [AlertMixin],
};
</script>

<style lang="scss" scoped>
.alert__text-box {
  white-space: normal;
  max-width: calc(100% - 40px);
}
</style>
