<template>
  <div
    class="user-info"
    :class="$vuetify.breakpoint.width < 475 ? 'flex-column align-start' : ''"
  >
    <div
      class="d-flex align-center"
      :class="$vuetify.breakpoint.width < 475 ? 'ml-4' : ''"
    >
      <v-avatar size="32px" style="align-self: start" class="mt-2">
        <v-img :src="getAvatarUrl(instance.owner.email, 48)" />
      </v-avatar>
      <div class="d-flex flex-column justify-center ml-3">
        <p
          class="
            p-2
            mb-0
            font-weight-bold
            gray--text
            text--darken-2
            d-flex
            primary-onhover
          "
          :class="
            $vuetify.breakpoint.smAndDown
              ? 'flex-column align-start'
              : 'align-center'
          "
        >
          {{ instance.owner.name }} {{ isYou ? "(you)" : "" }}
        </p>
        <p
          v-if="instance.owner.name !== instance.owner.email"
          class="p-3 mb-0 mt-1 gray--text text--darken-1 primary-onhover"
        >
          {{ instance.owner.email }}
        </p>
      </div>
    </div>

    <div
      class="
        justify-self-end
        d-flex
        align-center
        justify-space-between
        p-2
        font-weight-600
        gray--text
        text--lighten-1
      "
      style="width: 223px"
      :class="$vuetify.breakpoint.width < 475 ? '' : 'ml-auto'"
    >
      <span class="ml-4">Owner</span>
    </div>
  </div>
</template>

<script>
import { getAvatarUrl } from "@/apis/HelperFunctions";

export default {
  props: {
    instance: Object,
  },
  data() {
    return {
      getAvatarUrl,
    };
  },
  computed: {
    isYou() {
      return this.$store.state.home.user.email == this.instance.owner.email;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-dropdown-select .v-list-item::before {
  background: currentColor !important;
}
.user-info {
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 62px;
  padding: 8px 16px 8px 8px;
  transition: background-color 0.24s ease;
  .primary-onhover {
    transition: color 0.24s ease;
  }

  &:hover {
    background-color: #f9f9fb;
    border-radius: 4px;
    .primary-onhover {
      color: var(--v-primary-base) !important;
    }
  }

  .user-info__pending-invite {
    color: var(--v-text-lighten1);
    margin-left: 8px;
    font-weight: $font-weight-light;
    font-size: $font-size-xxs;
    line-height: $line-height-xxs;
    display: flex;
    align-items: center;
  }
}

.v-btn::v-deep {
  .v-btn__content {
    font-weight: $font-weight-semibold;
  }
}

.input-dropdown::v-deep {
  border: none !important;
  .v-select__selections .v-select__selection {
    font-size: $font-size-base;
    line-height: $line-height-base;
    font-weight: $font-weight-semibold;
    color: var(--v-text-base);
    margin: 0px;
  }
  & > .v-input__control > .v-input__slot {
    &:after,
    &:before {
      display: none;
    }
  }
}

.error-onhover {
  &:hover {
    color: var(--v-error-base);
  }
}

.info-onhover {
  &:hover {
    color: var(--v-info-base);
  }
}

.v-avatar {
  border-color: var(--v-gray-lighten4) !important;
  border-style: solid !important;
  border-width: 1px !important;
}
</style>
