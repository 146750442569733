<template>
  <div>
    <v-avatar
      :size="size"
      elevation="0"
      large
      color=""
      :class="isSmall ? 'v-avatar--small' : ''"
      v-if="!fetching"
    >
      <instance-avatar-img-error
        v-if="installFailed"
        :isSmall="isSmall"
      />
      <v-img
        position="top center"
        v-else-if="image && !imgLoading"
        :src="image"
      ></v-img>

      <instance-avatar-img-not-available
        v-else-if="!imgLoading"
        :isSmall="isSmall"
      />

      <div
        class="icon-container elevation-4"
        :class="isSmall ? 'icon-container--small' : ''"
      >
        <DnsSslIndicator :instance="instance" />
      </div>
    </v-avatar>
    <v-skeleton-loader v-else type="avatar" />
  </div>
</template>

<script>
import DnsSslIndicator from "./security/DnsSslIndicator.vue";
import InstanceAvatarImgNotAvailable from "./instances/InstanceAvatarImgNotAvailable.vue";
import InstanceAvatarImgError from "./instances/InstanceAvatarImgError.vue";

import Api from "@/apis/Api";

export default {
  components: {
    DnsSslIndicator,
    InstanceAvatarImgNotAvailable,
    InstanceAvatarImgError
  },
  props: {
    img: String,
    instance: Object,
    size: {
      type: [Number, String],
      default: 64,
    },
    fetching: Boolean,
    installFailed: Boolean,
  },
  data: function () {
    return {
      image: false,
      imgLoading: true,
    };
  },
  computed: {
    isSmall: function () {
      return this.size < 64 ? true : false;
    },
  },
  methods: {
    getInstanceImg: function () {
      if (!this.img) {
        this.image = false;
        this.imgLoading = false;
        return;
      }
      Api.cached()
        .get(this.img)
        .then((data) => {
          this.image = data.data.base64;
        })
        .catch(() => {
          this.image = false;
        })
        .finally(() => {
          this.imgLoading = false;
        });
    },
  },
  mounted: function () {
    this.getInstanceImg();
  },
};
</script>

<style lang="scss" scoped>
.v-avatar {
  z-index: 9;
  position: relative;
  overflow: visible;
  border: none;
  border-color: var(--v-gray-lighten4) !important;
  border-style: solid !important;
  border-width: 1px !important;

  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.12),
    0px 8px 48px 6px rgba(0, 0, 0, 0.04), 0px 8px 32px rgba(0, 0, 0, 0.05);
  &--small {
    box-shadow: none;
  }
  @media (max-width: 975px) {
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.12),
      0px 8px 48px 6px rgba(0, 0, 0, 0.04);
  }
  .preview-not-available {
    border-radius: 100%;
  }
  .icon-container {
    position: absolute;
    right: -8px;
    bottom: -4px;
    z-index: 2;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    &--small {
      background-color: transparent;
      background-color: white;
      height: 24px;
      width: 24px;
      right: -4px;
      bottom: -8px;
    }
  }
}

.v-skeleton-loader::v-deep {
  .v-skeleton-loader {
    &__avatar {
      width: 64px;
      height: 64px;
    }
  }
}
</style>
