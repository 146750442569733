import Vue from 'vue'
import VueRouter from 'vue-router'
import Instances from '../views/Instances.vue'

import Auth from "../apis/Auth";
import store from '../store/store'
import i18next from 'i18next';

Vue.use(VueRouter)

let title = "PanelAlpha";
if (process.env.VUE_APP_DEMO_MODE && process.env.VUE_APP_DEMO_TITLE) {
  title = process.env.VUE_APP_DEMO_TITLE;
}

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { displayNavbar: true, requiresAuth: true },
    redirect: '/instances',
  },
  {
    path: '/login',
    name: 'Login',
    meta: { displayNavbar: false, requiresAuth: false, title: 'login' },

    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
  {
    path: '/sso-login',
    name: 'SsoLogin',
    meta: { displayNavbar: false, requiresAuth: false },

    component: () => import(/* webpackChunkName: "login" */ '../views/SsoLogin.vue'),
  },
  {
    path: '/accept-invite',
    name: 'AcceptInvite',
    meta: { displayNavbar: false, requiresAuth: false, title: 'accept-invite' },

    component: () => import(/* webpackChunkName: "login" */ '../views/AcceptInvite.vue'),
  },
  // Hosting Pages

  {
    path: '/hosting/',
    name: 'Hosting',
    props: true,
    meta: { displayNavbar: true, requiresAuth: true },

    component: () => import(/* webpackChunkName: "hosting" */ '../views/hosting/index.vue'),
    redirect: '/hosting/summary',
    children: [
      {
        path: '/hosting/summary',
        name: 'Summary',
        props: true,
        meta: { displayNavbar: true, requiresAuth: true, title: 'summary' },

        component: () => import(/* webpackChunkName: "summary" */ '../views/hosting/Summary.vue'),
      },
      {
        path: '/hosting/domains',
        name: 'Domains',
        props: true,
        meta: { displayNavbar: true, requiresAuth: true, title: 'domains' },

        component: () => import(/* webpackChunkName: "domains" */ '../views/hosting/Domains.vue'),
      },
      {
        path: '/hosting/ftp',
        name: 'FTP',
        props: true,
        meta: { displayNavbar: true, requiresAuth: true, title: 'ftp' },

        component: () => import(/* webpackChunkName: "ftp" */ '../views/hosting/Ftp.vue'),
      },
      {
        path: '/hosting/databases',
        name: 'Databases',
        props: true,
        meta: { displayNavbar: true, requiresAuth: true, title: 'databases' },

        component: () => import(/* webpackChunkName: "databases" */ '../views/hosting/Databases.vue'),
      },
      {
        path: '/hosting/cron',
        name: 'Cron',
        props: true,
        meta: { displayNavbar: true, requiresAuth: true, title: 'cron' },

        component: () => import(/* webpackChunkName: "cron" */ '../views/hosting/Cron.vue'),
      },
      {
        path: '/hosting/dns',
        name: 'DNS',
        props: true,
        meta: { displayNavbar: true, requiresAuth: true, title: 'dns' },

        component: () => import(/* webpackChunkName: "dns" */ '../views/hosting/Dns.vue'),
      },
      {
        path: '/hosting/email-addresses',
        name: 'Email Addresses',
        props: true,
        meta: { displayNavbar: true, requiresAuth: true, title: 'email-addresses' },

        component: () => import(/* webpackChunkName: "email-addresses" */ '../views/hosting/EmailAddresses.vue'),
      },
      {
        path: '/hosting/email-forwarders',
        name: 'Email Forwarders',
        props: true,
        meta: { displayNavbar: true, requiresAuth: true, title: 'email-forwarders' },

        component: () => import(/* webpackChunkName: "email-forwarders" */ '../views/hosting/EmailForwarders.vue'),
      },
    ]
  },

  //instance pages
  {
    path: '/instances',
    name: 'Instances',
    meta: { displayNavbar: true, requiresAuth: true, title: 'instances' },
    component: Instances,
  },
  {
    path: '/instances/:id',
    name: 'Instance',
    props: true,
    meta: { displayNavbar: true, requiresAuth: true },

    component: () => import(/* webpackChunkName: "instance" */ '../views/instance/index.vue'),
    redirect: '/instances/:id/general',
    children: [
      {
        path: 'general',
        name: 'GeneralInformation',
        props: true,
        meta: { displayNavbar: true, requiresAuth: true, title: 'general-information' },

        component: () => import(/* webpackChunkName: "instance-details" */ '../views/instance/General.vue')
      },
      {
        path: 'backups',
        name: 'Backups',
        props: true,
        meta: { displayNavbar: true, requiresAuth: true, title: 'backups' },

        component: () => import(/* webpackChunkName: "instance-backups" */ '../views/instance/Backups.vue')
      },
      {
        path: 'monitoring',
        name: 'Monitoring',
        props: true,
        meta: { displayNavbar: true, requiresAuth: true, title: 'monitoring' },

        component: () => import(/* webpackChunkName: "instance-monitoring" */ '../views/instance/Monitoring.vue')
      },
      {
        path: 'plugins',
        name: 'Plugins',
        props: true,
        meta: { displayNavbar: true, requiresAuth: true, title: 'plugins' },

        component: () => import(/* webpackChunkName: "instance-plugins" */ '../views/instance/Plugins.vue')
      },
      {
        path: 'advanced',
        name: 'Advanced',
        props: true,
        meta: { displayNavbar: true, requiresAuth: true },

        component: () => import(/* webpackChunkName: "instance-advanced" */ '../views/instance/advanced/index.vue'),
        redirect: { name: 'Details' },

        children: [
          {
            path: 'themes',
            name: 'Themes',
            props: true,
            meta: { displayNavbar: true, requiresAuth: true, title: 'themes' },

            component: () => import(/* webpackChunkName: "advanced-themes" */ '../views/instance/advanced/Themes.vue'),
          },
          {
            path: 'details',
            name: 'Details',
            props: true,
            meta: { displayNavbar: true, requiresAuth: true, title: 'details' },

            component: () => import(/* webpackChunkName: "advanced-details" */ '../views/instance/advanced/Details.vue'),
          },
          {
            path: 'cloudflare',
            name: 'Cloudflare',
            props: true,
            meta: { displayNavbar: true, requiresAuth: true, title: 'cloudflare' },

            component: () => import(/* webpackChunkName: "advanced-details" */ '../views/instance/advanced/Cloudflare.vue'),
          },
          {
            path: 'security',
            name: 'Security',
            props: true,
            meta: { displayNavbar: true, requiresAuth: true, title: 'security' },

            component: () => import(/* webpackChunkName: "advanced-security" */ '../views/instance/advanced/Security.vue'),
          },
          {
            path: 'logs',
            name: 'Logs',
            props: true,
            meta: { displayNavbar: true, requiresAuth: true, title: 'logs' },

            component: () => import(/* webpackChunkName: "advanced-logs" */ '../views/instance/advanced/Logs.vue'),
          },
          {
            path: 'wordpress-config',
            name: 'WordPress Config',
            props: true,
            meta: { displayNavbar: true, requiresAuth: true, title: 'wordpress-config' },

            component: () => import(/* webpackChunkName: "wordpress-config" */ '../views/instance/advanced/WordpressConfig.vue'),
          },
          {
            path: 'wordpress-users',
            name: 'Wordpress Users',
            props: true,
            meta: { displayNavbar: true, requiresAuth: true, title: 'users' },

            component: () => import(/* webpackChunkName: "wordpress-users" */ '../views/instance/advanced/WordpressUsers.vue'),
          },
          {
            path: 'auto-updates',
            name: 'Auto Updates',
            props: true,
            meta: { displayNavbar: true, requiresAuth: true, title: 'auto-updates' },

            component: () => import(/* webpackChunkName: "auto-updates" */ '../views/instance/advanced/AutoUpdates.vue'),
          },
          {
            path: 'developer-settings',
            name: 'Developer Settings',
            props: true,
            meta: { displayNavbar: true, requiresAuth: true, title: 'developer-settings' },
            component: () => import(/* webpackChunkName: "auto-updates" */ '../views/instance/advanced/DeveloperSettings.vue'),
          },
        ],
      },
    ]
  },

  //user settings
  {
    path: '/my-account',
    name: 'Account',
    meta: { displayNavbar: true, requiresAuth: true, title: 'my-account' },
    component: () => import(/* webpackChunkName: "my-account" */ '../views/Account.vue')
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    meta: { displayNavbar: false, requiresAuth: false, title: 'forgot-password' },
    component: () => import(/* webpackChunkName: "forgot-password" */ '../views/ForgotPassword.vue'),
  },
  {
    path: '/password-reset/change',
    name: 'Reset Password',
    meta: { displayNavbar: false, requiresAuth: false, title: 'reset-password' },
    component: () => import(/* webpackChunkName: "reset-password" */ '../views/ResetPassword.vue'),
  },
  {
    path: '/*',
    component: () => import(/* webpackChunkName: "advanced-configuration" */ '../views/PageNotFound.vue'),
    name: 'Error',
    meta: {
      title: title + ' - Page Not Found'
    },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, _, next) => {
  if (!store.state.home.app) {
    Auth.home().then((response) => {
      store.commit("setHome", response.data);
      next();
    })
  } else {
    next();
  }
});

router.beforeEach((to, _, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!Auth.isLoggedIn()) {
      next(`/login?redirect=${to.fullPath}`);
    } else {
      next((vm) => {
        vm.itemsLoading = true;
      });
    }
  } else {
    next()
  }

})

router.afterEach((to) => {
  Vue.nextTick(() => {

    let title = "PanelAlpha";

    if (process.env.VUE_APP_DEMO_MODE && process.env.VUE_APP_DEMO_TITLE) {
      title = process.env.VUE_APP_DEMO_TITLE;
    }
    else if(store.state.home.app.name) {
      title = store.state.home.app.name
    }

    if (to.meta.title) {
      document.title = i18next.t(`title.${to.meta.title}`, {
        app_name: title,
        instance_name: store.getters.getInstanceTitle
      });
    } else {
      document.title = title;
    }
  });
});

export default router
