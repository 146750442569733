<template>
  <v-dialog
    no-click-animation
    hide-overlay
    :overlay-opacity="0"
    transition="fade-transition"
    fullscreen
    persistent
    :value="isOpen ? true : false"
  >
    <v-card class="installation-card d-flex align-center mb-16">
      <div class="mx-auto align-center px-8 d-flex flex-column mt-8">
        <div class="d-flex justify-center">
          <v-img
            :width="$vuetify.breakpoint.mobile ? 171 : 200"
            :height="$vuetify.breakpoint.mobile ? 36 : 44"
            :src="customLogo ? customLogo : require('@/assets/logo.svg')"
            contain
            position="center center"
          />
        </div>
        <div class="d-flex justify-center pt-16">
          <h1 class="text--center d-flex flex-column align-center">
            <span class="text-center primary--text" v-if="!error && completed">
              Congratulations!
            </span>
            <span class="text-center error--text" v-else-if="error && completed"
              >Error!</span
            >
            <span class="text-center" v-if="!completed"
              >Your site is now being installed for you!</span
            >
            <span class="text-center" v-else-if="completed && !error"
              >Your site has been successfully installed!</span
            >
            <span class="text-center" v-else-if="completed && error"
              >Installation unsuccessfull, please try again.</span
            >
          </h1>
        </div>
        <div
          class="mt-8 installation-card__image"
          :class="
            !completed
              ? 'installation-card__image--working'
              : error
              ? 'installation-card__image--error'
              : 'installation-card__image--success'
          "
        >
          <loader
            :completed="completed"
            :size="120"
            :width="12"
            color="#ffffff"
          >
            <v-img v-if="!completed" src="../../assets/LogoPartialWhite.svg" />
            <v-img v-if="completed && !error" src="../../assets/Check.svg" />
            <v-img v-if="completed && error" src="../../assets/Failed.svg" />
          </loader>
          <v-img
            width="100vw"
            position="center top"
            height="315px"
            :src="theme.screenshot_url"
          />
        </div>
        <p class="p-1 pt-8 mb-0 text-center heading--text">
          {{
            !completed
              ? "This process may take even a few minutes, so in the meantime relax and enjoy the moment"
              : error
              ? "Something went wrong, please try again."
              : "Your website has been successfully installed, you can now start managing it by clicking below button."
          }}
        </p>
        <div
          class="d-flex pt-8"
          :class="$vuetify.breakpoint.mdAndDown ? 'flex-column' : ''"
        >
          <v-btn
            v-if="!error"
            width="210px"
            elevation="0"
            x-large
            outlined
            exact
            color="gray"
            class="v-size--xx-large"
            @click="$emit('gotoDashboard')"
            :class="$vuetify.breakpoint.mdAndDown ? 'mr-0' : 'mr-3'"
          >
            <span class="heading--text">Go to dashboard</span>
          </v-btn>
          <v-btn
            v-if="error"
            width="210px"
            elevation="0"
            x-large
            outlined
            color="gray"
            class="v-size--xx-large"
            @click="$emit('tryAgain')"
            :class="$vuetify.breakpoint.mdAndDown ? 'ml-0 mt-4' : 'ml-3'"
          >
            <span class="heading--text">Try Again</span>
          </v-btn>
          <v-btn
            v-if="completed && error"
            width="210px"
            elevation="0"
            x-large
            color="primary"
            class="primary v-size--xx-large mb-10"
            :class="$vuetify.breakpoint.mdAndDown ? 'ml-0 mt-4' : 'ml-3'"
            @click="$emit('gotoDashboard')"
          >
            Back to dashboard
          </v-btn>
          <v-btn
            v-else
            width="210px"
            elevation="0"
            x-large
            color="primary"
            class="primary v-size--xx-large mb-10"
            :class="$vuetify.breakpoint.mdAndDown ? 'ml-0 mt-4' : 'ml-3'"
            :disabled="!completed || error"
            @click="manageSite(installedInstance.id)"
          >
            Manage Your Site
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import Loader from "../Loader.vue";

export default {
  components: {
    Loader,
  },
  props: {
    isOpen: Boolean,
    theme: Object,
    completed: Boolean,
    error: Boolean,
    installedInstance: Object,
  },
  methods: {
    manageSite(id) {
      if (this.isDemo) {
        this.$root.$emit("manage-first-instance");
        this.$store.dispatch("deactivateAll");
        return;
      }

      this.$store.dispatch("deactivateAll");
      this.$router.push(
        id
          ? {
              path: `/instances/${id}/general`,
            }
          : undefined
      );
    },
  },
  watch: {
    isOpen: function (value) {
      if (value) {
        this.$store.dispatch("lockBodyScroll");
      } else {
        this.$store.dispatch("unlockBodyScroll");
      }
    },
  },
  computed: {
    isDemo() {
      return this.$store.state.home.user.email == "demo@panelalpha.com";
    },
    customLogo: function () {
      if (!this.$store.state.ThemeModule.logo) {
        return false;
      }

      return this.$store.state.ThemeModule.logo;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog {
  overflow: auto !important;
}

.installation-card {
  max-height: 100vw;
  max-width: 100vw;
  display: flex;
  align-items: center;

  > div {
    background: white;
    max-width: 684px;
    width: 100%;
  }

  &__image {
    width: 100%;
    position: relative;
    border-radius: 16px !important;
    box-shadow: 0px 12px 32px 6px rgba(0, 0, 0, 0.06),
      0px 16px 72px 8px rgba(0, 0, 0, 0.1);

    > .v-image {
      border-radius: 16px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 100%;
        backdrop-filter: blur(8px);
        z-index: 90;
      }
    }

    .loader {
      position: absolute;
      z-index: 100;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      display: flex;
      align-items: center;
    }

    &:before {
      background: linear-gradient(
        180deg,
        rgba(219, 219, 219, 0.5) 0%,
        #c4c4c4 100%
      );
      opacity: 1;
    }

    &:after {
      background: linear-gradient(
        180deg,
        rgba(219, 219, 219, 0.5) 0%,
        #c4c4c4 100%
      );
      opacity: 0;
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0px;
      top: 0px;
      z-index: 99;
      border-radius: 16px;
      transition: opacity 0.5s ease;
      // backdrop-filter: blur(8px);
    }

    &--error,
    &--success {
      &:before {
        opacity: 0;
      }

      &::after {
        opacity: 1;
      }
    }

    &--error {
      &:after {
        background: linear-gradient(
          180deg,
          rgba(241, 40, 40, 0.35) 0%,
          rgba(241, 40, 40, 0.7) 100%
        );
      }
    }

    &--success {
      &:after {
        background: linear-gradient(
          180deg,
          rgba(111, 198, 108, 0.5) 0%,
          #07c07e 100%
        );
      }
    }
  }
}

h1 {
  font-size: $font-size-h1;
  line-height: $line-height-h1;
}
</style>
