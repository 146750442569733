export const WordpressLanguageOptions = [
  { value: "en", text: "English" },
  { value: "ar", text: "Arabic" },
  { value: "sq", text: "Albanian" },
  { value: "bg_BG", text: "Bulgarian" },
  { value: "ca", text: "Catalan" },
  { value: "zh_CN", text: "Chinese(Simplified)" },
  { value: "zh_TW", text: "Chinese(Traditional)" },
  { value: "hr", text: "Croatian" },
  { value: "cs_CZ", text: "Czech" },
  { value: "da_DK", text: "Danish" },
  { value: "nl_NL", text: "Dutch" },
  { value: "fi", text: "Finnish" },
  { value: "fr_FR", text: "French" },
  { value: "de_DE", text: "German" },
  { value: "el", text: "Greek" },
  { value: "he_IL", text: "Hebrew" },
  { value: "hu_HU", text: "Hungarian" },
  { value: "id_ID", text: "Indonesian" },
  { value: "it_IT", text: "Italian" },
  { value: "ja", text: "Japanese" },
  { value: "ko_KR", text: "Korean" },
  { value: "nb_NO", text: "Norwegian" },
  { value: "fa_IR", text: "Persian" },
  { value: "pl_PL", text: "Polish" },
  { value: "pt_PT", text: "Portuguese" },
  { value: "pt_BR", text: "Portuguese-BR" },
  { value: "ro_RO", text: "Romanian" },
  { value: "ru_RU", text: "Russian" },
  { value: "sl_SI", text: "Slovenian" },
  { value: "sk_SK", text: "Slovak" },
  { value: "es_ES", text: "Spanish" },
  { value: "sv_SE", text: "Swedish" },
  { value: "th", text: "Thai" },
  { value: "tr_TR", text: "Turkish" },
  { value: "uk", text: "Ukrainian" },
  { value: "vi", text: "Vietnamese" },
  { value: "zh_HK", text: "Chinese (Hong Kong)" },
];

export const UserPrivilegesGroups = [
  {
    key: "general",
    text: "General Management",
  },
  {
    key: "wordpress",
    text: "WordPress Management",
  },
  {
    key: "hosting",
    text: "Manage Related Hosting",
    description: "If this hosting instance has other sites too, they will have an access to them as well.",
  },
];

export const UserPrivilegesList = [
  {
    key: "general.manage_labels",
    group: "general",
    text: "Change Label",
    tooltip: "",
  },
  {
    key: "general.change_domain",
    group: "general",
    text: "Set Domain",
    tooltip: "",
  },
  {
    key: "general.create_staging",
    group: "general",
    text: "Create Staging",
    tooltip: "",
  },
  {
    key: "general.push_to_live",
    group: "general",
    text: "Push to Live",
    tooltip: "",
  },
  {
    key: "general.view_logs",
    group: "general",
    text: "View Logs",
    tooltip: "",
  },
  {
    key: "general.view_reports",
    group: "general",
    text: "View Reports",
    tooltip: "",
  },
  {
    key: "general.delete_instance",
    group: "general",
    text: "Delete Instance",
    tooltip: "",
    color: "error",
  },
  {
    key: "wordpress.create_sso_link",
    group: "wordpress",
    text: "WP Admin SSO",
    tooltip: "",
    color: "info",
  },
  {
    key: "wordpress.update",
    group: "wordpress",
    text: "Update WordPress",
    tooltip: "",
  },
  {
    key: "wordpress.manage_auto_update",
    group: "wordpress",
    text: "Manage Auto Update",
    tooltip: "",
  },
  {
    key: "wordpress.manage_backups",
    group: "wordpress",
    text: "Manage Backups",
    tooltip: "",
  },
  {
    key: "wordpress.manage_auto_backup",
    group: "wordpress",
    text: "Manage Automatic Backup",
    tooltip: "",
  },
  {
    key: "wordpress.update_site_name",
    group: "wordpress",
    text: "Update Site Name",
    tooltip: "",
  },
  {
    key: "wordpress.clear_cache",
    group: "wordpress",
    text: "Clear Cache",
    tooltip: "",
  },
  {
    key: "wordpress.manage_maintenance_mode",
    group: "wordpress",
    text: "Set Maintenance Mode",
    tooltip: "",
  },
  {
    key: "wordpress.manage_debug_mode",
    group: "wordpress",
    text: "Set Debug Mode",
    tooltip: "",
  },
  {
    key: "wordpress.manage_themes",
    group: "wordpress",
    text: "Manage Themes",
    tooltip: "",
  },
  {
    key: "wordpress.manage_plugins",
    group: "wordpress",
    text: "Manage Plugins",
    tooltip: "",
  },
  {
    key: "wordpress.manage_config",
    group: "wordpress",
    text: "Manage WordPress Config",
    tooltip: "",
  },
  {
    key: "wordpress.manage_users",
    group: "wordpress",
    text: "Manage WordPress Users",
    tooltip: "",
  },
  {
    key: "hosting.manage_email",
    group: "hosting",
    text: "Manage Email Accounts",
    tooltip: "",
  },
  {
    key: "hosting.manage_dns",
    group: "hosting",
    text: "Manage DNS",
    tooltip: "",
  },
  {
    key: "hosting.manage_domains",
    group: "hosting",
    text: "Manage Domains",
    tooltip: "",
  },
  {
    key: "hosting.manage_ssl",
    group: "hosting",
    text: "Manage SSL Certificates",
    tooltip: "",
  },
  {
    key: "hosting.manage_ftp",
    group: "hosting",
    text: "Manage FTP Accounts",
    tooltip: "",
  },
  {
    key: "hosting.manage_mysql",
    group: "hosting",
    text: "Manage Mysql Databases",
    tooltip: "",
  },
  {
    key: "hosting.manage_cron",
    group: "hosting",
    text: "Manage Cron Jobs",
    tooltip: "",
  },
  {
    key: "hosting.phpmyadmin_sso",
    group: "hosting",
    text: "PHPMyAdmin SSO",
    tooltip: "",
  },
  {
    key: "general.manage_shared_access",
    group: "general",
    text: "Manage Shared Access",
    tooltip: "",
  },
];