<template>
  <div
    class="table-item table-item--name item--title"
    :class="main ? 'group-start' : ''"
  >
    <template v-if="main">
      <div class="item item--main">
        <component
          :is="item.hasInstallFailed() ? 'span' : 'router-link'"
          :to="
            item.hasInstallFailed()
              ? undefined
              : `/instances/${item.id}/general`
          "
        >
          <instance-avatar
            :instance="item"
            :size="40"
            :img="item.img"
            :installFailed="item.hasInstallFailed()"
            :ssl="item.info.ssl"
          />
        </component>
        <div class="item__title-box">
          <component
            :is="item.hasInstallFailed() ? 'span' : 'router-link'"
            :to="
              item.hasInstallFailed()
                ? undefined
                : `/instances/${item.id}/general`
            "
            class="d-flex align-center"
          >
            <h6 class="item__title text-truncate">
              {{ item.title }}
            </h6>
            <div
              class="suspend-chip ml-2"
              v-if="item.service_status === 'suspended'"
            >
              <v-icon color="sand darken-2" size="12" class="mr-1">
                $suspended
              </v-icon>
              Suspended
            </div>
          </component>
          <a :href="item.url" class="item__url instance-domain-link p-4">{{
            item.url
          }}</a>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="item">
        <div class="item__title-box">
          <router-link :to="`/instances/${item.subinstance.id}/general`">
            <p class="mb-0 p-2 font-weight-600 item__title">
              <v-tooltip
                transition="custom-tooltip"
                open-delay="150"
                bottom
                z-index="99"
                offset-overflow
                nudge-bottom="12px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div class="d-inline" v-on="on" v-bind="attrs">
                    <label-chip
                      class="subinstance-label"
                      xsmall
                      :item="{
                        title: item.subinstance.subinstanceLabel,
                        color: 'info lighten-4 info--text',
                      }"
                    />
                  </div>
                </template>

                <span>
                  This instance has
                  {{ item.subinstance.subinstanceLabel }} subinstance</span
                >
              </v-tooltip>
              {{ item.subinstance.title }}
            </p>
          </router-link>

          <a
            :href="item.subinstance.url"
            class="item__url p-4 instance-domain-link"
            >{{ item.subinstance.url }}</a
          >
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import InstanceAvatar from "../../InstanceAvatar.vue";
import LabelChip from "../../labels/LabelChip.vue";

export default {
  components: { InstanceAvatar, LabelChip },
  props: {
    item: {
      type: Object,
    },
    main: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
    },
  },
};
</script>

<style lang="scss" scoped>
.table-item {
  padding-left: 0;
}

.item {
  max-width: 100%;
  width: 100%;
  &__title {
    color: map-get($text, headings);
    transition: color 0.24s ease;
    &:hover {
      color: map-get($primary, darken1);
    }
  }
  &__title-box {
    max-width: calc(100% - 40px);
    > a {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  &:not(.item--main) {
    margin-left: 56px;
    position: relative;
    &::after {
      content: "";
      position: absolute;

      border-bottom: 1px solid map-get($gray, base);
      border-left: 1px solid map-get($gray, base);

      width: 30px;
      height: 68px;
      left: -36px;
      top: -32px;
    }
  }
}

.subinstance-label {
  text-transform: capitalize;
}

.suspend-chip {
  border-radius: 5px;
  background-color: var(--v-sand-lighten5);
  color: var(--v-sand-darken2);
  font-weight: $font-weight-bold;
  font-size: $font-size-xxs;
  line-height: $line-height-xxs;
  padding: 4px;
}
.v-application--is-rtl{
        .item {
            &:not(.item--main) {
            margin-right: 56px;
            &::after {
            border-bottom: unset;
            border-top: 1px solid map-get($gray, base);
            left: unset;
            right: -36px;
            top: -32px;
            rotate: 180deg;
            }
            .item__title-box{
                margin-right: 0px;
            }
        }
    }
}
</style>
