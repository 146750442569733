<template>
  <div class="preview-not-available">
    <div class="preview-not-available__content">
      <preview-not-available class="preview-not-available__img" />
      <div class="preview-not-available__text p-4">
        Preview not available.
        <v-tooltip
          transition="custom-tooltip"
          open-delay="150"
          bottom
          z-index="99"
          offset-overflow
          nudge-bottom="4px"
        >
          <template v-slot:activator="{ on, attrs }">
            <a href="#" v-on="on" v-bind="attrs">Why?</a>
          </template>

          <span v-if="instance.isNew()">
            It takes few minutes after instance creation to capture a screenshot
            of the website.
          </span>
          <span v-else>
            Could not access the website to take a screenshot. DNS propagation
            for the domain may not be finished or DNS settings are incorrect.
            The preview is updated once a day.
          </span>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import PreviewNotAvailable from "../illustrations/preview-not-available-icon.vue";

export default {
  components: {
    PreviewNotAvailable,
  },
  props: {
    instance: Object,
  },
};
</script>

<style lang="scss" scoped>
.preview-not-available {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    // background: var(--v-primary-lighten4);
    opacity: 0.8;
    height: 100%;
    width: 100%;
    border-radius: 8px 8px 0 0;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 8px;
    position: relative;
    z-index: 2;
  }
  &__text {
    color: map-get($text, base);
    margin-top: 4px;
    a {
      color: map-get($primary, darken1);
      transition: color 0.24s ease;
      &:hover {
        color: map-get($primary, base);
      }
    }
  }
}
</style>