import i18next from 'i18next'
import I18NextVue from 'i18next-vue'
import Backend from 'i18next-http-backend'

i18next
	.use(Backend)
	.init({
		debug: false,
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false
		},
	});

export { i18next, I18NextVue };