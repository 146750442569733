<template>
  <div class="floating-button">
    <v-btn
      rounded
      color="primary"
      elevation="4"
      @click="startInstallationProcess"
      class="p-2 font-weight-bold"
    >
      <v-icon size="24" class="white--text">$plus</v-icon>
      Add Instance
    </v-btn>
  </div>
</template>

<script>
export default {
  methods: {
    startInstallationProcess: function () {
      this.$store.dispatch("startInstallationProcess");
    },
  },
};
</script>

<style lang="scss" scoped>
.floating-button {
  position: fixed;
  bottom: 16px;
  right: 24px;
  z-index: 99;
}
</style>