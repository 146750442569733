<template>
  <v-app-bar
    app
    elevation="4"
    color="white"
    class="app-bar"
    :height="
      $vuetify.breakpoint.smAndDown
        ? '100'
        : $vuetify.breakpoint.mobile
        ? '64'
        : '88'
    "
  >
    <v-container class="px-1">
      <div class="d-flex justify-space-between align-center">
        <v-toolbar-title class="d-flex align-center">
          <router-link to="/" class="logo-link">
            <v-img
              :width="$vuetify.breakpoint.mobile ? 171 : 200"
              :height="$vuetify.breakpoint.mobile ? 36 : 44"
              :src="customLogo ? customLogo : require('@/assets/logo.svg')"
              contain
              :position="$vuetify.rtl ? 'center right' : 'center left'"
            ></v-img>
          </router-link>

          <navbar-top-navigation
            class="ml-11"
            v-if="!$vuetify.breakpoint.smAndDown"
          />

          <v-btn
            text
            color="info"
            v-if="$store.state.demoMode"
            href="https://www.panelalpha.com/"
            class="ml-2"
          >
            <span
              v-if="!$vuetify.breakpoint.mobile"
              class="p-2 font-weight-light"
            >
              Visit Website
            </span>
            <v-icon size="16" color="info" class="ml-2">$externallink</v-icon>
          </v-btn>
        </v-toolbar-title>

        <div class="navbar__menu-items" v-if="loggedIn">
          <add-new-instance-button
            v-if="!$vuetify.breakpoint.mobile"
          ></add-new-instance-button>

          <notifications-dropdown />

          <user-info-dropdown></user-info-dropdown>
        </div>
      </div>
      <div
        v-if="$vuetify.breakpoint.smAndDown"
        class="d-flex justify-space-between align-center"
      >
        <navbar-top-navigation />
      </div>
    </v-container>
  </v-app-bar>
</template>

<script>
import AddNewInstanceButton from "./AddNewInstanceDialogButton.vue";
import NotificationsDropdown from "./NotificationsDropdown.vue";
import UserInfoDropdown from "./UserInfoDropdown.vue";
import NavbarTopNavigation from "./NavbarTopNavigation.vue";

import Auth from "@/apis/Auth";

export default {
  components: {
    AddNewInstanceButton,
    NotificationsDropdown,
    UserInfoDropdown,
    NavbarTopNavigation,
  },
  data() {
    return {
      loggedIn: false,
    };
  },
  computed: {
    customLogo: function () {
      if (!this.$store.state.ThemeModule.logo) {
        return false;
      }

      return this.$store.state.ThemeModule.logo;
    },
  },
  mounted() {
    this.$root.$on("loggedIn", () => {
      this.loggedIn = true;
    });
    this.$root.$on("loggedOut", () => {
      this.loggedIn = false;
    });
    this.loggedIn = Auth.isLoggedIn();
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  &__menu-items {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }
}

.logo-link {
  max-width: 100%;
  .v-image {
      image-rendering: crisp-edges;
      image-rendering: -moz-crisp-edges;
      image-rendering: -o-crisp-edges;
      image-rendering: -webkit-optimize-contrast;
  }
}
</style>
