<template>
  <div
    class="label-list"
    v-bind="attrs"
    v-on="on"
    :class="[
      containerClass,
      !chips ? 'label-list--simple' : '',
      maxWidth ? 'flex-nowrap w-100' : '',
      filtersActive ? 'label-list--filters' : '',
      dots ? 'label-list--dots' : '',
    ]"
  >
    <template v-if="chips">
      <template v-if="!filtersActive">
        <label-chip
          v-for="(item, index) in displayLabels"
          :key="index"
          :item="item"
          :small="small"
          :xsmall="xsmall"
          :removable="removable"
          :clickable="filtersActive || removable"
          ref="items"
          v-on="$listeners"
        />
      </template>
      <template v-else>
        <v-tooltip
          transition="custom-tooltip"
          open-delay="150"
          bottom
          nudge-bottom="4px"
          nudge-left="4px"
          v-for="(item, index) in displayLabels"
          :key="index"
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on" v-bind="attrs">
              <label-chip
                :item="item"
                :small="small"
                :xsmall="xsmall"
                :removable="removable"
                :clickable="filtersActive || removable"
                ref="items"
                v-on="$listeners"
              />
            </div>
          </template>
          <span>Click to filter instances by this tag</span>
        </v-tooltip>
      </template>

      <span v-if="loading">
        <Loader noMargins size="16" />
      </span>
    </template>
    <template v-else>
      <label-simple
        :key="index"
        v-for="(item, index) in displayLabels"
        :item="item"
        :dot="dots"
        :small="small"
        ref="items"
      />
      <span v-if="loading">
        <Loader noMargins size="16" />
      </span>
    </template>
    <template v-if="remainingAsButton">
      <div
        v-if="remainingLabels > 0"
        v-ripple
        @click="showRemaining"
        class="label-list__items-remaining info--text p-5 font-weight-regular"
        ref="remaining"
      >
        {{ remainingLabels ? `+${remainingLabels}` : "" }}
      </div>
      <div
        v-else-if="remainingLabels <= 0 && (instance.getUserPrivileges('general.manage_labels'))"
        v-ripple
        @click="showRemaining"
        class="label-list__items-remaining info--text p-5 font-weight-regular"
        ref="remaining"
      >
        Add More
      </div>
    </template>
    <template v-else>
      <div
        class="label-list__items-remaining info--text p-5"
        v-if="remainingLabels > 0"
        ref="remaining"
      >
        +{{ remainingLabels }}
      </div>
    </template>
  </div>
</template>

<script>
import LabelSimple from "../labels/LabelSimple.vue";
import LabelChip from "./LabelChip.vue";
import Loader from "../Loader.vue";

export default {
  components: {
    LabelSimple,
    LabelChip,
    Loader,
  },
  data: function () {
    return {
      maxItems: 0,
    };
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: Number,
      default: 0,
    },
    on: {
      type: Object,
    },
    attrs: {
      type: Object,
    },
    active: {
      type: Boolean,
      default: false,
    },
    chips: {
      type: Boolean,
      default: false,
    },
    dots: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    xsmall: {
      type: Boolean,
      default: false,
    },
    removable: {
      type: Boolean,
      default: true,
    },
    remainingAsButton: {
      type: Boolean,
      default: false,
    },
    maxLabelsDisplayed: {
      type: Number,
      default: 2,
    },
    containerClass: {
      type: String,
      default: "",
    },
    filtersActive: {
      type: Boolean,
      default: false,
    },
    labels: Array,
    instance: Object,
  },
  computed: {
    maxItemsInRow: function () {
      if (!this.maxWidth) {
        return false;
      }

      let maxLabels = 0;
      let remainingWidth = this.maxWidth - 65;

      this.labels.forEach((item) => {
        let labelWidth = this.calcLabelsWidth(item);
        if (remainingWidth > labelWidth) {
          maxLabels = maxLabels + 1;

          remainingWidth = remainingWidth - labelWidth;
        }
      });

      return maxLabels;
    },
    displayLabels: function () {
      if (this.maxItemsInRow) {
        let newLabels = this.labels.slice(0, this.maxItems);
        return newLabels.slice(0, this.maxItemsInRow);
      }

      if (this.maxItems === 0) {
        return this.labels;
      } else {
        let newLabels = this.labels.slice(0, this.maxItems);
        return newLabels.slice(0, this.maxLabelsDisplayed);
      }
    },
    remainingLabels: function () {
      return this.labels.length - this.displayLabels.length;
    },
  },
  methods: {
    showRemaining: function () {
      this.$emit("showRemaining");
    },
    handleLabelClick: function (item) {
      this.$emit("toggleFilter", item);
    },
    calcLabelsWidth: function (label) {
      return label.title.length * 7 + 24;
    },
  },
  mounted: function () {
    this.maxItems = this.maxLabelsDisplayed;
    this.$nextTick(() => {
      this.maxItemsInRow;
    });
  },
  watch: {
    labels: function () {
      if (this.maxLabelsDisplayed > 0) {
        this.maxItems = this.maxLabelsDisplayed;
      }
    },
    maxWidth: function () {
      this.maxItems = this.maxLabelsDisplayed;
    },
  },
};
</script>

<style lang="scss" scoped>
.label-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 24px;
  &--dots {
    margin-left: 8px;
  }
  &__item {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 6px;
  }
  &__items-remaining {
    color: map-get($text, base);
    font-weight: $font-weight-light;
    border-radius: 10px;
    min-width: 20px;
    padding: 2px 4px;

    &--btn {
      border-radius: 50%;
      font-size: $font-size-xs;
      line-height: $line-height-xs;
      font-weight: $font-weight-normal;
      height: 24px;
      width: 24px;
      padding: 0 !important;
      // &.label-list__items-remaining--active {
      //   border: none;
      //   background: map-get($primary, lighten2);
      //   color: map-get($primary, darken1);
      // }
    }
  }
  &--filters {
    .label-chip {
      cursor: pointer;
    }
  }
  &--simple {
    min-width: 60px;
  }
}
.v-application--is-rtl{
  .label-list{
    &--filters{
        .label-chip{
            margin-right: 0px;
            margin-left: 8px;
        }
    }
  }  
}
</style>
