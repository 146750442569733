<template>
  <v-dialog
    hide-overlay
    :overlay-opacity="0"
    transition="fade-transition"
    persistent
    ref="dialog"
    v-model="isOpen"
    absolute
  >
    <div class="card-overlay" @click="$refs.dialog.animateClick()" />

    <v-card style="width: 600px" class="" v-if="isOpen">
      <v-card-text height="800px" class="px-8 pt-8">
        <div class="mb-8 d-flex align-center">
          <h4 class="font-weight-light">Add New Instance</h4>
          <v-btn x-small icon @click="$emit('removeModal')" class="ml-auto">
            <v-icon size="24" color="gray">$close</v-icon>
          </v-btn>
        </div>

        <div class="selection-box">
          <checkbox-big
            class="pa-6"
            :checked="selectedType === 0"
            @check="selectedType = 0"
          >
            <template v-slot:custom>
              <div class="text-left" style="height: 100%">
                <v-icon size="24" color="primary" class="mb-4">
                  $pluscircle
                </v-icon>
                <h5 class="p-1 mb-4">Create New WordPress Instance</h5>
                <p class="p-2 font-weight-thin gray--text text--darken-1 mb-0">
                  Just a few quick steps to create a new WordPress instance.
                </p>
              </div>
            </template>
          </checkbox-big>
          <checkbox-big
            class="pa-6"
            :checked="selectedType === 1"
            @check="selectedType = 1"
          >
            <template v-slot:custom>
              <div class="text-left" style="height: 100%">
                <v-icon size="24" class="mb-4" color="primary">
                  $installIcon
                </v-icon>
                <h5 class="p-1 mb-4">Import Existing WordPress Instance</h5>
                <p class="p-2 font-weight-thin gray--text text--darken-1 mb-0">
                  Import your site from another provider by filling out a simple
                  form.
                </p>
              </div>
            </template>
          </checkbox-big>
        </div>
      </v-card-text>
      <v-card-actions
        class="pt-8 d-flex align-center justify-space-between"
        :class="$vuetify.breakpoint.width < 550 ? 'flex-column-reverse' : ''"
      >
        <div :class="$vuetify.breakpoint.width < 550 ? 'mt-4 w-100' : ''">
          <step-indicator :currentStep="1" :maxSteps="4" />
        </div>
        <div
          class="d-flex align-center"
          :class="
            $vuetify.breakpoint.width < 550 ? 'flex-column-reverse w-100' : ''
          "
        >
          <v-btn
            large
            @click="$emit('removeModal')"
            text
            :block="$vuetify.breakpoint.width < 550 ? true : false"
            elevation="0"
            class="mr-3"
          >
            <span class="font-weight-bold gray--text text--darken-1">
              Cancel
            </span>
          </v-btn>
          <v-btn
            large
            @click="$emit('next', selectedType)"
            color="primary"
            :block="$vuetify.breakpoint.width < 550 ? true : false"
            elevation="0"
          >
            <span class="font-weight-bold">
              Continue
            </span>
            <v-icon class="ml-2 mr-0" size="20">$arrowright</v-icon>

          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import StepIndicator from "../StepIndicator.vue";
import CheckboxBig from "../buttons/CheckboxBig.vue";

export default {
  components: {
    CheckboxBig,
    StepIndicator,
  },
  props: {
    isOpen: Boolean,
  },
  data: () => ({
    selectedType: 0,
  }),
  watch: {
    isOpen: function (value) {
      this.selectedType = 0;

      if (value) {
        this.$nextTick(() => {
          this.$store.dispatch("lockBodyScroll");
        });
      } else {
        this.$store.dispatch("unlockBodyScroll");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog__content::v-deep {
  .v-dialog {
    overflow-y: auto;
    overflow-x: hidden;
  }
}
.v-card::v-deep {
  .selection-box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    .checkbox-big {
      border-radius: 8px;
      &::after {
        border-radius: 8px;
      }
    }
    @media (max-width: 700px) {
      grid-template-columns: 1fr;
    }
  }
  .v-card__text {
    -webkit-line-clamp: unset !important;
  }
  @media (max-width: 480px) {
    .v-card__text,
    .v-card__actions {
      padding-left: 24px !important;
      padding-right: 24px !important;
    }
  }
}
</style>
