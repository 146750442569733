<template>
  <div>
    <v-container class="mt-13">
      <v-row align="stretch">
        <v-col cols="12" class="d-flex flex-column">
          <h1>Something went wrong</h1>
          <span>Try again in a minute</span>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

export default {

};
</script>
