var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{ref:"dialog",attrs:{"hide-overlay":"","overlay-opacity":0,"transition":"fade-transition","persistent":"","absolute":""},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('div',{staticClass:"card-overlay",on:{"click":function($event){return _vm.$refs.dialog.animateClick()}}}),(_vm.isOpen)?_c('v-card',{staticStyle:{"width":"600px"}},[_c('v-card-text',{staticClass:"px-8 pt-8",attrs:{"height":"800px"}},[_c('div',{staticClass:"mb-8 d-flex align-start"},[_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"font-weight-light p-1 base--text"},[_vm._v(" Import Existing WordPress Instance ")]),_c('h4',{},[_vm._v("FTP Account Configuration")])]),_c('v-btn',{staticClass:"ml-auto",attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.$emit('removeModal')}}},[_c('v-icon',{attrs:{"size":"24","color":"gray"}},[_vm._v("$close")])],1)],1),_c('v-divider',{staticClass:"mb-5"}),_c('p',{staticClass:"p-2 gray--text text--darken-2 mb-5 font-weight-light"},[_vm._v(" To import your data, our system needs to connect via FTP/SFTP to download the instance and database files. Please provide the details below. ")]),_c('v-form',{ref:"form",staticClass:"form-box"},[_c('div',{staticClass:"full"},[_c('span',{staticClass:"gray--text text--darken-1 mb-1",class:_vm.hostnameError ? 'error--text' : ''},[_vm._v(" Hostname/IP ")]),_c('v-text-field',{staticClass:"mt-1 mb-0 v-input--md",attrs:{"outlined":"","hide-details":"","error":_vm.hostnameError ? true : false,"dense":"","rules":[
              function (v) { return !!v || 'Hostname is Required'; },
              function (v) { return (v && v.length > 0) || 'Hostname is Required'; },
              function (v) { return (v && v.length < 256) || 'Hostname is too long'; } ],"placeholder":"ftp.source.example.com"},model:{value:(_vm.hostname),callback:function ($$v) {_vm.hostname=$$v},expression:"hostname"}})],1),_c('div',{staticClass:"half"},[_c('span',{staticClass:"gray--text text--darken-1 mb-1",class:_vm.protocolError ? 'error--text' : ''},[_vm._v(" Protocol ")]),_c('v-select',{staticClass:"mt-1 mb-0",attrs:{"items":_vm.availableProtocols,"error":_vm.protocolError ? true : false,"outlined":"","dense":"","hide-details":"","rules":[
              function (v) { return !!v || 'Protocol is Required'; },
              function (v) { return (v && v.length > 0) || 'Protocol is Required'; },
              function (v) { return _vm.availableProtocols.includes(v) || 'Invalid Protocol'; } ],"menu-props":{
              offsetY: true,
            }},model:{value:(_vm.protocol),callback:function ($$v) {_vm.protocol=$$v},expression:"protocol"}})],1),_c('div',{staticClass:"half"},[_c('span',{staticClass:"gray--text text--darken-1 mb-1",class:_vm.portError ? 'error--text' : ''},[_vm._v(" Server Port ")]),_c('v-text-field',{staticClass:"mt-1 mb-0 v-input--md",attrs:{"outlined":"","error":_vm.portError ? true : false,"hide-details":"","type":"number","rules":[
              function (v) { return !!v || 'Port is Required'; },
              function (v) { return (v && v > 0 && v < 65536) ||
                'Port must be between 1 and 65535'; },
              function (v) { return (v && v % 1 === 0) || 'Port must be an integer'; } ],"dense":"","placeholder":"21"},model:{value:(_vm.port),callback:function ($$v) {_vm.port=$$v},expression:"port"}})],1),(_vm.protocol == 'SFTP')?[_c('div',{staticClass:"full"},[_c('span',{staticClass:"gray--text text--darken-1 mb-1",class:_vm.authMethodError ? 'error--text' : ''},[_vm._v(" Authentication Method ")]),_c('v-select',{staticClass:"mt-1 mb-0",attrs:{"items":_vm.authMethods,"error":_vm.authMethodError ? true : false,"outlined":"","dense":"","hide-details":"","rules":[
                function (v) { return !!v || 'Authentication Method is Required'; },
                function (v) { return (v && v.length > 0) || 'Authentication Method is Required'; } ],"menu-props":{
                offsetY: true,
              }},model:{value:(_vm.authMethod),callback:function ($$v) {_vm.authMethod=$$v},expression:"authMethod"}})],1),_c('div',{staticClass:"half"},[_c('span',{staticClass:"gray--text text--darken-1 mb-1",class:_vm.sftpUsernameError ? 'error--text' : ''},[_vm._v(" SFTP User ")]),_c('v-text-field',{staticClass:"mt-1 mb-0 v-input--md",attrs:{"outlined":"","rules":[
                function (v) { return !!v || 'Username is Required'; },
                function (v) { return (v && v.length > 0) || 'Username is Required'; },
                function (v) { return (v && v.length < 256) || 'Username is too long'; } ],"hide-details":"","error":_vm.sftpUsernameError ? true : false,"dense":"","placeholder":"Username"},model:{value:(_vm.sftpUsername),callback:function ($$v) {_vm.sftpUsername=$$v},expression:"sftpUsername"}})],1),_c('div',{staticClass:"half"},[(_vm.authMethod == 'password')?_c('span',{staticClass:"gray--text text--darken-1 mb-1",class:_vm.sftpPasswordError ? 'error--text' : ''},[_vm._v(" SFTP Password ")]):_vm._e(),(_vm.authMethod == 'password')?_c('v-text-field',{staticClass:"mt-1 mb-0 v-input--md",attrs:{"outlined":"","hide-details":"","rules":[
                function (v) { return !!v || 'Password is Required'; },
                function (v) { return (v && v.length > 0) || 'Password is Required'; },
                function (v) { return (v && v.length < 256) || 'Password is too long'; } ],"error":_vm.sftpPasswordError ? true : false,"dense":"","type":"password","placeholder":"Password"},model:{value:(_vm.sftpPassword),callback:function ($$v) {_vm.sftpPassword=$$v},expression:"sftpPassword"}}):_vm._e()],1),(_vm.authMethod == 'ssh_key')?[_c('div',{staticClass:"half"},[_c('span',{staticClass:"gray--text text--darken-1 mb-1",class:_vm.sftpPrivateKeyError ? 'error--text' : ''},[_vm._v(" Private Key ")]),_c('v-text-field',{staticClass:"mt-1 mb-0 v-input--md",attrs:{"outlined":"","rules":[
                  function (v) { return !!v || 'Private Key is Required'; },
                  function (v) { return (v && v.length > 0) || 'Private Key is Required'; } ],"hide-details":"","error":_vm.sftpPrivateKeyError ? true : false,"dense":"","type":"password","placeholder":"Private Key"},model:{value:(_vm.sftpPrivateKey),callback:function ($$v) {_vm.sftpPrivateKey=$$v},expression:"sftpPrivateKey"}})],1),_c('div',{staticClass:"half"},[_c('span',{staticClass:"gray--text text--darken-1 mb-1",class:_vm.sftpPassphraseError ? 'error--text' : ''},[_vm._v(" Passphrase (optional) ")]),_c('v-text-field',{staticClass:"mt-1 mb-0 v-input--md",attrs:{"outlined":"","hide-details":"","rules":[],"error":_vm.sftpPassphraseError ? true : false,"dense":"","type":"password","placeholder":"Passphrase"},model:{value:(_vm.sftpPassphrase),callback:function ($$v) {_vm.sftpPassphrase=$$v},expression:"sftpPassphrase"}})],1)]:_vm._e()]:[_c('div',{staticClass:"half"},[_c('span',{staticClass:"gray--text text--darken-1 mb-1",class:_vm.usernameError ? 'error--text' : ''},[_vm._v(" FTP User ")]),_c('v-text-field',{staticClass:"mt-1 mb-0 v-input--md",attrs:{"outlined":"","rules":[
                function (v) { return !!v || 'Username is Required'; },
                function (v) { return (v && v.length > 0) || 'Username is Required'; },
                function (v) { return (v && v.length < 256) || 'Username is too long'; } ],"hide-details":"","error":_vm.usernameError ? true : false,"dense":"","placeholder":"Username"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1),_c('div',{staticClass:"half"},[_c('span',{staticClass:"gray--text text--darken-1 mb-1",class:_vm.passwordError ? 'error--text' : ''},[_vm._v(" FTP Password ")]),_c('v-text-field',{staticClass:"mt-1 mb-0 v-input--md",attrs:{"outlined":"","hide-details":"","rules":[
                function (v) { return !!v || 'Password is Required'; },
                function (v) { return (v && v.length > 0) || 'Password is Required'; },
                function (v) { return (v && v.length < 256) || 'Password is too long'; } ],"error":_vm.passwordError ? true : false,"dense":"","type":"password","placeholder":"Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],_c('div',{staticClass:"full"},[_c('span',{staticClass:"gray--text text--darken-1 mb-1",class:_vm.installedPathError ? 'error--text' : ''},[_vm._v(" Installed Path ")]),_c('v-text-field',{staticClass:"mt-1 mb-0 v-input--md",attrs:{"outlined":"","rules":[],"hide-details":"","error":_vm.installedPathError ? true : false,"dense":"","placeholder":"wp"},model:{value:(_vm.installedPath),callback:function ($$v) {_vm.installedPath=$$v},expression:"installedPath"}})],1)],2),_c('v-scale-transition',[(_vm.formError && !_vm.connectionTestLoading)?_c('div',{staticClass:"error-box mt-5"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"error","size":"20"}},[_vm._v("$alertcircle")]),_c('span',{staticClass:"error--text"},[_c('b',{staticClass:"error--text"},[_vm._v("Failed: ")]),_vm._v(" "+_vm._s(_vm.formError)+" ")])],1):_vm._e()])],1),_c('v-card-actions',{staticClass:"pt-8 d-flex align-center justify-space-between"},[_c('div',[_c('step-indicator',{attrs:{"currentStep":3,"maxSteps":4}})],1),_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{staticClass:"mr-3",attrs:{"large":"","text":"","elevation":"0"},on:{"click":function($event){return _vm.$emit('removeModal')}}},[_c('span',{staticClass:"font-weight-bold gray--text text--darken-1"},[_vm._v(" Cancel ")])]),_c('v-btn',{attrs:{"loading":_vm.connectionTestLoading,"large":"","color":"primary","elevation":"0"},on:{"click":_vm.handleContinueButtonClick}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" Continue "),_c('v-icon',{staticClass:"mr-0",attrs:{"size":"20"}},[_vm._v("$arrowright")])],1)])],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }